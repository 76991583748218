
import Vue from "vue";
import Search from "../components/search.vue";
import NetworkStatistics from "../components/network/cards/network-statistics/network-statistics.vue";
import HaltingAnalysis from "@/components/node/tools/halting-analysis/halting-analysis.vue";
import { Network } from "@stellarbeat/js-stellar-domain";
import { Component, Prop, Watch } from "vue-property-decorator";
import Store from "@/store/Store";
import NetworkVisualNavigator from "@/components/visual-navigator/network-visual-navigator.vue";
import NetworkSideBar from "@/components/network/sidebar/network-side-bar.vue";
import CrawlTime from "@/components/crawl-time.vue";
import SimulationBadge from "@/components/simulation-badge.vue";
import TimeTravelBadge from "@/components/time-travel-badge.vue";
import { BBreadcrumb, BAlert } from "bootstrap-vue";
import LazyHydrate from "vue-lazy-hydration";
import { Route } from "vue-router";

@Component({
  name: "dashboard",
  components: {
    BAlert,
    LazyHydrate,
    "network-analysis": () =>
      import(
        "@/components/network/tools/network-analysis/network-analysis.vue"
      ),
    TimeTravelBadge,
    SimulationBadge,
    CrawlTime,
    NetworkSideBar,
    NetworkVisualNavigator,
    Search,
    Statistics: NetworkStatistics,
    HaltingAnalysis,
    BBreadcrumb: BBreadcrumb,
  },
})
export default class Dashboard extends Vue {
  @Prop({ default: "graph" })
  public view!: string;

  @Watch("$route", { immediate: true })
  public onRouteChanged(to: Route) {
    if (to.params.publicKey) {
      this.store.selectedNode = this.network.getNodeByPublicKey(
        to.params.publicKey
      );
      if (!this.store.selectedNode) {
        this.$router.push({
          name: "network-dashboard",
          query: {
            view: this.$route.query.view,
            network: this.$route.query.network,
            at: this.$route.query.at,
          },
        });
      }
    } else this.store.selectedNode = undefined;
    if (to.params.organizationId) {
      this.store.selectedOrganization = this.network.getOrganizationById(
        to.params.organizationId
      );
      if (!this.store.selectedOrganization) {
        this.$router.push({
          name: "network-dashboard",
          query: {
            view: this.$route.query.view,
            network: this.$route.query.network,
            at: this.$route.query.at,
          },
        });
      }
    } else this.store.selectedOrganization = undefined;

    if (to.query.center === "1" || !to.query.center) {
      this.store.centerNode = this.store.selectedNode;
    }
  }

  @Watch("store.haltingAnalysisPublicKey")
  public onHaltingAnalysisPublicKeyChanged(publicKey?: string) {
    if (publicKey)
      this.$nextTick(() => {
        this.$scrollTo("#halting-analysis-card");
      });
  }

  get breadCrumbs() {
    let crumbs = [];
    crumbs.push({
      text: this.store.getNetworkIdPretty(),
      to: {
        name: "network-dashboard",
        query: {
          view: this.$route.query.view,
          network: this.$route.query.network,
        },
      },
    });

    if (this.selectedNode) {
      if (this.selectedNode.organizationId)
        crumbs.push({
          text: this.network.getOrganizationById(
            this.selectedNode.organizationId
          ).name,
          to: {
            name: "organization-dashboard",
            params: {
              organizationId: this.selectedNode.organizationId,
            },
          },
          active: false,
        });
      crumbs.push({
        text: this.selectedNode.displayName,
        active: true,
      });
    } else if (this.selectedOrganization)
      crumbs.push({
        text: this.selectedOrganization.name,
        active: true,
      });
    return crumbs;
  }

  get store(): Store {
    return this.$root.$data.store;
  }

  get selectedNode() {
    return this.store.selectedNode;
  }

  get selectedOrganization() {
    return this.store.selectedOrganization;
  }

  get network(): Network {
    return this.$root.$data.store.network;
  }

  get isSimulation(): boolean {
    return this.store.isSimulation;
  }
}
